import React, { useState } from 'react';
import 'assets/styles/pages/sidebar.scss';
import 'assets/styles/pages/dashboardstatus.scss';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { Link, navigate } from 'gatsby';
import Form from 'react-bootstrap/Form';
import editiconpencil from 'assets/images/admin/edit-icon.svg';
import binicon from 'assets/images/admin/recycle-bin.png';
import Projectlocationpops from 'components/admin/projectlocationpops';
//import sidebar css from react-pro-sidebar module and our custom css
import 'react-pro-sidebar/dist/css/styles.css';
import 'assets/styles/pages/customheader.scss';

import AdminService from 'services/admin/admin.service';
import { useEffect } from 'react';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import Googlemaps from 'components/map-initiative/googlemaps';
import Viewthubnailslider from 'components/register-plantation/viewthumbnail-slider';
import ProjectViewthubnailslider from './project-thumbnail-slider';
import Pagination from "react-js-pagination";

import noimage from "assets/images/noImage.png";
import { doAdminLogout } from './login';
import { isMobile } from 'react-device-detect';

//import Initiativeviewspecies from 'components/admin/initiative-view';
export default function Projectstatuspage() {

  const adminApi = new AdminService();
  const [plantationList, setPlantationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mapModal, setMapModal] = useState(false);
  const [mapValues, setMapValues] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [perPage] = useState(10);
  const [activePage, setActivePage] = useState(1)
  const [trackId, setTrackId] = useState('')
  const [trackDelete, setTrackDelete] = useState(false)
  const [onSearch, setOnSearch] = useState(false);

  const deleteOpen = (data) => {
    setTrackId(data);
    setTrackDelete(true);
  };
  const trackClose = () => {
    setTrackDelete(false);
    setTrackId("");
  };

  const deleteProject = async () => {
    setLoading(true);
    await adminApi
      .deleteProjects(trackId)
      .then((data) => {
        if (data.status === 200) {
          setLoading(false);
          toast.success("Project Deleted Successfully");
          setTrackDelete(false);
          listPlanters();
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            toast.error("JWT Token has Expired");
            navigate("/admin/");
          } else {
            toast.error(error.response.data.message);
          }
        }
      });
  };
  const handlePageChange = async (pageNumber) => {
    await setActivePage(pageNumber);
    let inputs = {
      pageNumber: pageNumber - 1,
      pageSize: perPage,
      name: "",
      status: "",
      toBeFiltered: false,
    };
    setLoading(true);
    await adminApi
      .listProjects({ inputs })
      .then((data) => {
        if (data.status === 200) {
          setLoading(false);
          setPlantationList(data.data);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            toast.error("JWT Token has Expired");
            navigate("/admin");
          } else {
            toast.error(error.response.data.message);
          }
        }
      });
  };
  const listPlanters = async () => {
    let inputs = {
      pageNumber: 0,
      pageSize: perPage,
      name: "",
      status: "",
      toBeFiltered: false,
    };
    setLoading(true);
    await adminApi
      .listProjects({ inputs })
      .then((data) => {
        if (data.status === 200) {
          setLoading(false);
          setActivePage(1);
          setPlantationList(data.data);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          validationMessage(error);
        }
      });
  };
  const validationMessage = (data) => {
    if (data.response.status === 401) {
      toast.error("Authentication failed");
      setTimeout(() => {
        doAdminLogout();
        navigate("/admin/");
      }, 3000);
    } else if (data.response.status === 403) {
      toast.error("Un Authorized user");
      setTimeout(() => {
        doAdminLogout();
        navigate("/admin/");
      }, 3000);
    } else {
      toast.error(data.response.data.message);
    }
  };
  const editProject = (data) => {
    const editData = data;
    navigate("/admin/projectedit", { state: { editData } });
  };
  const searchPlanters = async (e) => {
    const value = e.target.value;
    setSearchName(value);
    if (value.length > 2) {
      let inputs = {
        pageNumber: 0,
        pageSize: 10,
        name: value,
        status: statusValue,
        toBeFiltered: true,
      };
      setLoading(true);
      const plantationResponse = await adminApi.listProjects({ inputs });
      if (plantationResponse !== undefined) {
        if (plantationResponse.status === 200) {
          setPlantationList(plantationResponse.data);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      } else {
        setLoading(false);
      }
    }
    if (value.length === 0) {
      setStatusValue("");
      listPlanters();
    }
  };

  const statusOption = async (data) => {
    const value = data.target.value;
    setStatusValue(value);
    setActivePage(1);
    let inputs = {
      pageNumber: 0,
      pageSize: 10,
      name: searchName,
      status: value,
      toBeFiltered: value === "" ? false : true,
    };
    setLoading(true);
    const plantationResponse = await adminApi.listProjects({ inputs });
    if (plantationResponse !== undefined) {
      if (plantationResponse.status === 200) {
        setPlantationList(plantationResponse.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Something went wrong");
      }
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    listPlanters();
  }, []);
  return (
    <>
      <div className="dashboard-status-wrapper">
        <div className="row">
          <div className="col-md-12">
            <h3 className="dark-theme-green margin-btm"> PROJECTS </h3>
          </div>
          <div className="col-md-3">
            <div className="project-status-select">
              <Form.Control
                className={
                  onSearch === true
                    ? "projectselect all-project-input-box"
                    : "projectselect all-project-input-box search-box-input"
                }
                type="text"
                placeholder=""
                onChange={(e) => {
                  searchPlanters(e);
                  setOnSearch(true);
                }}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="project-status-select">
              <Form.Select
                className="all-status-input-box"
                aria-label="All status"
                value={statusValue}
                onChange={statusOption}
              >
                <option value="">All</option>
                <option value="2">Active</option>
                <option value="1">Inactive</option>
                <option value="3">Completed</option>
              </Form.Select>
            </div>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <Link
              to="/admin/addproject"
              className="btn btn-white-button fl-right"
            >
              + Add Project
            </Link>
          </div>
        </div>

        <div className="row mg-top20">
          {plantationList && plantationList.totalNoOfRecords !== 0 ? (
            <>
              {plantationList.projects &&
                plantationList.projects.map((projects, index) => {
                  return (
                    <div className="col-md-6" key={index + 1}>
                      <div className="project-card-wrapper">
                        <div className="wid-40 padding-10">
                          {projects.projectImageUrls &&
                          projects.projectImageUrls ? (
                            <ProjectViewthubnailslider
                              images={projects.projectImageUrls}
                              key={index + 1}
                            />
                          ) : (
                            <img src={noimage} alt="default Image" />
                          )}
                          {/* <img className='img-fluid img-100' src={projects.projectImageUrls && projects.projectImageUrls[0]} alt='bellicon' height="100%" width="100%" /> */}
                        </div>
                        <div className="dashboard-card-body wid-60 padding10">
                          <div className="dis-title-status">
                            <h5 className="heading-text">{projects.name}</h5>
                            <span className="status-text right-hightlight-msg">
                              {projects.status === "INACTIVE" ? (
                                <span style={{ color: "red" }}>Inactive</span>
                              ) : projects.status === "ACTIVE" ? (
                                "Active"
                              ) : projects.status === "COMPLETED" ? (
                                "Completed"
                              ) : null}
                            </span>
                          </div>
                          <div className="dis-title-status">
                            <span className="small-tree-text mg-right40">
                              {" "}
                              Planted Trees
                            </span>
                            <span className="small-tree-text">
                              {" "}
                              {projects.totalSpeciesCount}{" "}
                            </span>
                          </div>
                          <div className="dis-title-status">
                            <span className="small-tree-text mg-right10">
                              {" "}
                              Available Trees
                            </span>
                            <span className="small-tree-text">
                              {" "}
                              {projects.availableSpeciesCount}{" "}
                            </span>
                          </div>
                          <div className="dis-title-status">
                            <span className="small-tree-text mg-right30">
                              {" "}
                              Target Trees
                            </span>
                            <span className="small-tree-text">
                              {" "}
                              {projects.targetCount}{" "}
                            </span>
                          </div>
                          <div className="dis-title-status">
                            <span className="small-tree-text mg-right30">
                              {" "}
                              Donated Trees
                            </span>
                            <span className="small-tree-text">
                              {" "}
                              {projects.donatedTrees}{" "}
                            </span>
                          </div>
                          <p className="date-time-text">
                            Mobile No :{" "}
                            {projects && projects.phoneNumber
                              ? projects.phoneNumber
                              : ""}{" "}
                          </p>
                          <p className="date-time-text lower-case ellispe">
                            Email Id :{" "}
                            {projects && projects.email ? projects.email : ""}{" "}
                          </p>
                          <p className="date-time-text">
                            {" "}
                            {moment(new Date(projects.projectStartDate)).format(
                              "ddd, MMM D YYYY"
                            )}{" "}
                          </p>

                          <div
                            className={
                              isMobile
                                ? `item-direction-wr1`
                                : `item-direction-wr right-alig-icon`
                            }
                          >
                            <Projectlocationpops data={projects} />

                            {/* <span className='gap-circle-icon'>
                      {' '}
                      <button className='circle-view' to='/admin/projectedit' onClick={() => { openMaps(projects) }}>
                        {' '}
                        <img className='img-fluid' src={editiconpencil} alt='bellicon' />
                      </button>{' '}
                    </span> */}
                            <span className="gap-circle-icon">
                              {" "}
                              <button
                                className="circle-view"
                                to="/admin/projectedit"
                                onClick={() => {
                                  editProject(projects);
                                }}
                              >
                                {" "}
                                <img
                                  className="img-fluid"
                                  src={editiconpencil}
                                  alt="bellicon"
                                />
                              </button>{" "}
                            </span>
                            <span className="gap-circle-icon">
                              {" "}
                              <button
                                className="circle-view"
                                to="/admin/dashboardview"
                              >
                                {" "}
                                <img
                                  className="img-fluid bin-w-pad"
                                  onClick={() => {
                                    deleteOpen(projects.id);
                                  }}
                                  src={binicon}
                                  alt="bellicon"
                                />
                              </button>{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </>
          ) : (
            <p>No Records Found</p>
          )}
        </div>
        {loading === false && plantationList.totalNoOfRecords > 10 ? (
          <div>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={plantationList.totalNoOfRecords}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </div>
        ) : null}
      </div>
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <Modal show={mapModal} size="lg" centered>
        <div>
          <Modal.Header closeButton>Map</Modal.Header>
          <Googlemaps data={mapValues} />
        </div>
      </Modal>

      <Modal show={trackDelete} centered>
        <Modal.Header closeButton onClick={trackClose}>
          <Modal.Title>Delete Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete ?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="whiteColor"
            style={{ color: "black" }}
            onClick={trackClose}
          >
            No
          </Button>
          <Button variant="primary" onClick={deleteProject}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
}
