import React from 'react';

import AdminLayout from '../../adminlayout/index';

import SEO from 'components/seo/index';

import Projectstatuspage from 'components/admin/projectstatuspage';

const Projectstatus = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness admin' />
      <AdminLayout>
      <div>
        <Projectstatuspage />
      </div>
      </AdminLayout>
    </>
  );
};

export default Projectstatus;
